<template>
	<div :class="$style.auth">
		<div class="py-5 dF jC align-items-end mt-auto">
			<img src="@/assets/logo.svg" alt="Bildhive Logo" style="height:40px;" />
		</div>
		<transition>
			<div v-if="mode === 'login'" class="pl-5 pr-5 pt-5 pb-5 bg-white text-center" :class="$style.container">
				<div class="text-dark font-size-30 py-3">Sign in Your Bildhive <br /> Admin Account</div>
				<a-form class="mb-3" :form="form" @submit="handleSubmit">
					<a-form-item :style="[emailRequirements ? { '': '' } : { 'margin-bottom': '0 !important' },]">
						<a-input size="large" placeholder="Email" autocomplete="off" :class="{ redBorder: !emailRequirements }"
							:style="[emailRequirements ? { '': '' } : { 'border-color': 'var(--red) !important' },]"
							v-decorator="['email', { rules: [{ required: true, message: 'Please input your username!' },], },]"
							v-model="email">
							<a-tooltip slot="suffix" overlayClassName="change-tooltip-color">
								<a-icon v-show="!emailRequirements" type="warning" style="color: var(--red)" />
							</a-tooltip>
						</a-input>
						<div v-show="!emailRequirements" style="overflow: auto;">
							<p style="margin-bottom: 0px !important; text-align: left; color: var(--red);">
								Hmm... That's not a valid email address.
							</p>
						</div>
					</a-form-item>
					<a-form-item>
						<a-input-password size="large" placeholder="Password" autocomplete="off"
							v-decorator="['password', { rules: [{ required: true, message: 'Please input your password!' }] }]" />
					</a-form-item>
					<a-form-item>
						<router-link style="float: left;" class="text-med-gray" to="/system/forgot-password">
							<u>Forgot password?</u>
						</router-link>
					</a-form-item>

					<a-button :disabled="loading" :loading="loading" type="primary" htmlType="submit" size="large"
						class="text-center w-100 font-weight-semibold font-size-18">Log in</a-button>
				</a-form>
			</div>
			<div v-else-if="mode === 'email'" class="pl-5 pr-5 pt-5 pb-5 bg-white text-center"
				:class="$style.container">
				<div class="text-dark font-size-30">{{ t === 1 ? 'Verify this device' : 'Too many login Attempts' }}
				</div>
				<div class="text-center font-size-16 mb-3 text-dark-gray">
					<span v-if="t === 2">Over 5 failed login attemps detected in the last 24 hours. </span>We have sent
					a
					verification code to your email <span class="text-primary" style="display:inline-block">({{
		dashedEmail
	}})</span>.
					Please enter the code from the email below to verify {{ t === 1 ? 'this device' : 'your account' }}
				</div>
				<a-form class="mb-3">
					<a-form-item label="Verification code">
						<a-input-password size="large" placeholder="Code" :maxLength="8" class="br2" v-model="code"
							@keyup.enter="handleVerification" />
					</a-form-item>
					<a-form-item v-if="t === 1 || t == 2">

						<a-checkbox id="remember" v-model="v60" class="mr-2" />
						<label for="remember">Verify this {{ t == 1 ? 'device' : 'account' }} for the next 60 days</label>
					</a-form-item>

					<a-button :disabled="loading" :loading="loading" @click="handleVerification" type="primary"
						size="large" class="text-center w-100 font-weight-semibold font-size-18">
						Verify {{ t === 1 ? 'Device' : 'Account' }}
					</a-button>
				</a-form>
			</div>
			<div v-else-if="mode === 'authenticator'" class="pl-5 pr-5 pt-5 pb-5 bg-white text-center"
				:class="$style.container">
				<div class="text-dark font-size-30"> Two-factor Authentication </div>
				<div class="text-center font-size-16 mb-3 text-dark-gray">
					Open your two-factor authenticator (TOTP) app or browser extension to view your authentication code.
				</div>
				<a-form class="mb-3">
					<a-form-item>
						<template #label>
							<h5>
								Authentication code
							</h5>
						</template>
						<a-input size="large" placeholder="XXXXXX" :maxLength="6" class="br2" v-model="code" @keyup.enter="handleVerification"/>
					</a-form-item>
					<a-button :disabled="loading" :loading="loading" @click="handleVerification" type="primary" size="large"
						class="text-center btn btn-primary w-100 font-weight-semibold font-size-18 br0">
						Verify Account
					</a-button>

					<a-divider></a-divider>
					<div style="float: left;">
						<div class="font-size-18">Having problems?</div>
						<a-button type="link" block @click="mode = 'recovery'" class="font-size-16">
							Use a recovery code
						</a-button>
					</div>
				</a-form>
			</div>
			<div v-else-if="mode === 'recovery'" class="pl-5 pr-5 pt-5 pb-5 bg-white text-center"
				:class="$style.container">
				<div class="text-dark font-size-30"> Two-factor Recovery </div>
				<div class="text-center font-size-16 mb-3 text-dark-gray">
					If you are unable to access your mobile device, enter one of your recovery codes to verify your
					identity.
				</div>
				<a-form class="mb-3">
					<a-form-item>
						<template #label>
							<h5>
								Recovery code
							</h5>
						</template>
						<a-input size="large" placeholder="XXXXX-XXXXX" :maxLength="11" class="br2" v-model="code" @keyup.enter="handleVerification"/>
					</a-form-item>
					<a-button :disabled="loading" :loading="loading" @click="handleVerification" type="primary"
						size="large"
						class="text-center btn btn-primary w-100 font-weight-semibold font-size-18 br0">
						Verify Account
					</a-button>

					<a-divider></a-divider>
					<div style="float: left;">
						<div class="font-size-18">Don't have a recovery code?</div>
						<a-button type="link" block @click="mode = 'authenticator'" class="font-size-16">
							Use your authenticator app
						</a-button>
					</div>
				</a-form>
			</div>
		</transition>
		<footer-bh />
	</div>
</template>

<script>
import { saveAuth, parseError, $auth } from "bh-mod";
import FooterBh from "@/components/common/FooterTerms.vue";

export default {
	components: { FooterBh },
	data: function () {
		return {
			loading: false,
			mode: "login",
			form: this.$form.createForm(this),
			t: 0,
			type: '',
			email: "",
			code: '',
			v60: false,
			token: ''
		};
	},
	computed: {
		dashedEmail() {
			// take this.email and return it with dashes except for the last 5 characters
			let length = this.email.length
			let last5 = this.email.slice(length - 7, length)
			let first = '*'.repeat(length - 7)
			return first + last5
		},
		fingerPrint() {
			return this.$store.state.fingerPrint.id;
		},
		emailRequirements() {
			let requirements = true;
			if (
				(this.email.match(/[@]+/) == null ||
					this.email.match(/[.]+/) == null) &&
				this.email.length > 4
			) {
				requirements = false;
			}
			return requirements;
		},
	},
	methods: {
		handleSuccess(tokens, manual = false) {
			this.loading = true;
			$auth.defaults.headers["Authorization"] = `Bearer ${tokens.jwt}`;
			$auth
				.get("/users/me")
				.then(({ data }) => {
					if (this.$sentry) {
						this.$sentry.setUser(data)
					}
					saveAuth(tokens, data, this.$store);
					this.$nprogress.done();
					this.loading = false;
					if (data.mustResetPassword) {
						this.$router.push("/settings/accounts?password-reset");
					} else {
						this.$router.push("/");
					}
				})
				.catch((err) => {
					this.loading = false;
					let msg = parseError(
						err,
						"Login error. Please review your credentials and try again!"
					);
					if (manual) {
						this.$notification["warning"]({
							message: "Error",
							description: msg,
							duration: 3,
						});
					}
				});
		},
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields(async (err, values) => {
				if (!err) {
					this.loading = true;
					this.$nprogress.start();
					let { success = false, data, t, value, type, message, token } = await this.$auth
						.login(values.email.trim().toLowerCase(), values.password, this.fingerPrint);

					if (success) {
						this.handleSuccess(data, true);
					} else if (t && type && value) {
						this.$nprogress.done();
						this.mode = type
						this.t = t
						this.type = type
						this.token = token
					} else {
						this.$nprogress.done();
						if (message && message.response && message.response.data && message.response.data.message) {
							if (typeof message.response.data.message == 'string') {
								this.$notification["warning"]({
									message: "Error",
									description: message.response.data.message,
									duration: 3,
								});
							} else if (typeof message.response.data.message == 'object') {
								if (message.response.data.message[0] && message.response.data.message[0].messages[0] && message.response.data.message[0].messages[0].message) {
									this.$notification["warning"]({
										message: "Error",
										description: message.response.data.message[0].messages[0].message,
										duration: 3,
									});
								} else {
									this.$notification["warning"]({
										message: "Error",
										description: "Please check your credentials and try again.",
										duration: 3,
									});
								}
							} else {
								this.$notification["warning"]({
									message: "Error",
									description: "Please check your credentials and try again.",
									duration: 3,
								});
							}
						} else {
							this.$notification["warning"]({
								message: "Error",
								description: "Please check your credentials and try again.",
								duration: 3,
							});
						}
					}
					this.loading = false;
				}
			});
		},
		handleVerification() {
			this.$nprogress.start();
			this.loading = true
			let payload = {
				email: this.email.toLowerCase(),
				code: this.code,
				v60: this.v60,
				renew: true,
			}
			let url = '/auth/vc';

			if (this.mode === 'authenticator') {
				url = '/2fa/otp/validate'

				delete payload.code;
				delete payload.v60;
				payload.otp = this.code
				payload.token = this.token
			} else if (this.mode === 'recovery') {
				url = '/2fa/recovery-code/validate'

				delete payload.v60;
				payload.token = this.token
			}
			$auth.post(url, payload, {
				headers: {
					'x-fp': this.fingerPrint
				}
			}).then(({ data }) => {
				this.loading = false
				if (data.jwt) {
					this.handleSuccess(data, true);
				}
				this.$nprogress.done();
			}).catch((err) => {
				console.error('Error =>', err)
				this.$nprogress.done();
				this.loading = false
				this.codeError = true
				let message = 'Oops! Something went wrong.'
				if (err.response && err.response.data) {
					message = err.response.data.message
				}
				this.$message.error(message)
			})
		},
	},
	created() {
	},
};
</script>

<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>

<style lang="scss" scoped>
.br0 {
	border-radius: 0 !important;
}

.redBorder {
	border: 1px solid var(--red) !important;
}
</style>
