<template>
  <div>
    <login />
  </div>
</template>
<script>
import Login from '@/components/system/Auth/Login'
export default {
  components: {
    Login,
  },
}
</script>
